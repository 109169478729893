import React from "react"
import { TRAINING_VIDEO } from "./../../data/types"

const StartVideo = ({ unLockStep, setProgress, progress }) => {
  setProgress(progress)
  return (
    <div className="entry">
      <div className="card fade">
        <h2>Start Training</h2>
        <p style={{ color: "#888", margin: ".75em", fontWeight: 100 }}>
          Press continue to proceed to the DigitalMint teller training video.
          There will be 5 multiple choice questions provided after the video is
          completed.{" "}
          <u style={{ textDecorationColor: "green" }}>
            Failure to answer all questions correctly will result in a replay of
            the training video.
          </u>
          <br />
          <br />
          Please make sure enough time is allocated to complete the training.
          The video is 3 minutes long and cannot be stop or paused. <br />{" "}
          <br />
          You must answer all questions correctly for your training to be
          considered complete. DigitalMint will contact you with a PIN and
          Username after completion.
        </p>
        <button
          className="btn"
          type="submit"
          onClick={() => unLockStep(TRAINING_VIDEO)}
        >
          Continue
        </button>
      </div>
    </div>
  )
}

export default StartVideo
