import React, { Fragment, useState, useEffect } from "react";
import ReactCodeInput from "react-code-input"
import Spinner from "../Icons/Spinner";
import { PHONE_VERFICATION } from "../../data/types"


const PinVerification = ({ style, isLoading, setIsLoading, unLockStep, setProgress, progress }) => {
    setProgress(progress)
    const [verficationCode, setVerficationCode] = useState("");
    const code = verficationCode === "581739"
    const completed = verficationCode.length === 6;

    useEffect(() => {
        if (code && completed) {
            setTimeout(() => {
                unLockStep(PHONE_VERFICATION)
            }, 500)
            setIsLoading(false)
        }
    }, [verficationCode, code, completed, setIsLoading, unLockStep])

    const onChange = e => setVerficationCode(e)

    return <div className="entry">
        <div className={`card ${(!code && completed) && "error error-text"}`}>
            <div className="columns">
                <div className="column">
                    <h2 style={{ textAlign: "center" }}>Teller Training Portal</h2>
                    <Fragment>
                        <p style={{ color: "#888", margin: 0, padding: 0, }}><small>Enter 6-Digit Verfication Code</small></p>
                        <div className="pinInput">
                          <ReactCodeInput
                              pattern="[0-9]*"
                              novalidate
                              type='number'
                              name="pin"
                              fields={6}
                              {...style}
                              onChange={onChange}
                              value={verficationCode}
                              className={(!code && completed) && "error"} />
                            </div>
                        <br />
                        <div style={{ height: 20 }}>
                            {!isLoading && <div className="spinner"><Spinner height={40} /></div>}
                        </div>
                    </Fragment>
                </div>
            </div>
        </div>
    </div>
}

export default PinVerification;
