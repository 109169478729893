import React from "react"
import { QUESTIONAIRE } from "../../data/types"

const SummaryReview = ({ unLockStep, setProgress, progress }) => {
  setProgress(progress)
  return (
    <div className="entry">
      <div className="card fade">
        <h2>Rules to Remember</h2>
        <br />
        <ul style={{ width: "inherit", padding: "0 1em" }}>
          <li>
            Transaction Maximum $2,500. Customers have a daily maximum of $5,000
            (which would be 2 separate transactions).
          </li>
          <li>
            {" "}
            A customer can apply to get a daily limit of up to $20k by calling
            DigitalMint & providing their social security number.
          </li>
          <li>
            Customers are only allowed to send to their own Bitcoin wallets.
            Once you complete a transaction it is IMPOSSIBLE to reverse.{" "}
          </li>
          <li>
            If you have any questions CALL DigitalMint Teller Support
            immediately at (312) 632-0934.
          </li>
        </ul>
        <button
          className="btn"
          type="submit"
          onClick={() => {
            unLockStep(QUESTIONAIRE)
            setProgress(75)
          }}
        >
          Continue
        </button>
      </div>
    </div>
  )
}

export default SummaryReview
