import React, { useState } from "react"
import { START_VIDEO } from "../../data/types"
import Input from "../Form/Input"

const useEnrollmentForm = callback => {
  const [inputs, setInputs] = useState({})
  const handleSubmit = e => {
    if (e) {
      e.preventDefault()
    }
    callback(e)
  }

  const handleInputChange = e => {
    e.persist()
    setInputs(inputs => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }))
  }

  return {
    handleSubmit,
    handleInputChange,
    inputs,
  }
}

const Enrollment = ({
  locationName,
  unLockStep,
  setProgress,
  progress,
  setUser,
}) => {
  const { inputs, handleInputChange, handleSubmit } = useEnrollmentForm(() => {
    setUser(inputs)
    unLockStep(START_VIDEO)
  })
  setProgress(progress)

  inputs["form-name"] = "enroll"
  inputs.operator = locationName

  return (
    <div className="entry">
      <div className="card fade">
        <div className="columns is-centered">
          <div className="column">
            <div className="__component-form">
              <form
                className="form-container"
                name="enroll"
                method="post"
                data-netlify={true}
                netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <div
                  className="columns is-centered"
                  style={{ width: "inherit" }}
                >
                  <h2
                    style={{
                      textAlign: "center",
                      display: "flex",
                      marginBottom: ".5em",
                    }}
                  >
                    {locationName}
                  </h2>
                </div>
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input
                  type="hidden"
                  name="form-name"
                  value={inputs["form-name"]}
                />
                <div className="columns" style={{ width: "inherit" }}>
                  <div className="column">
                    <Input
                      onChange={handleInputChange}
                      value={inputs.firstName}
                      name="firstName"
                      id="firstName"
                      label="First Name"
                      placeholder="Satoshi"
                      required
                    />
                  </div>
                </div>
                <div className="columns" style={{ width: "inherit" }}>
                  <div className="column">
                    <Input
                      onChange={handleInputChange}
                      value={inputs.lastName}
                      name="lastName"
                      id="lastName"
                      label="Last Name"
                      type="text"
                      placeholder="Nakamoto"
                      required
                    />
                  </div>
                </div>
                <div className="columns" style={{ width: "inherit" }}>
                  <div className="column">
                    <Input
                      onChange={handleInputChange}
                      value={inputs.email}
                      id="email"
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="support@digitalmint.io"
                      required
                    />
                  </div>
                </div>
                <div className="columns" style={{ width: "inherit" }}>
                  <div className="column">
                    <Input
                      onChange={handleInputChange}
                      value={inputs.phone}
                      name="phone"
                      id="phone"
                      label="Cell Phone"
                      placeholder="8444056170"
                      required
                    />
                  </div>
                </div>
                <div className="columns" style={{ width: "inherit" }}>
                  <div className="column is-pulled-left">
                    <button className="btn" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Enrollment
