import React from "react";

export const Input = ({
    name,
    label,
    placeholder,
    type = "text",
    value,
    onChange,
    required
}) => {
    return (
        <div className="__component-input">
            <label htmlFor={name}>
                <span>
                    {label}
                </span>
                {required && <small>required</small>}
            </label>
            <div className="input-text">
                <input
                    className="input-form"
                    value={value}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    required={required}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};


export default Input;