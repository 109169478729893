import React from "react"
import Validation from '../components/Steps/Validation'
import Navbar from "./../components/Navigation/Navbar"
import "../components/layout.css"
import "../components/app.css"

const IndexPage = () =>
  <div className="home">
    <Navbar />
    <Validation />
    <form name="enroll" data-netlify="true" netlify-honeypot="bot-field" hidden>
      <input type="text" name="form-name" value="enroll" readOnly />
      <input type="text" name="firstName" />
      <input type="text" name="lastName" />
      <input type="text" name="operator" />
      <input type="email" name="email" />
      <input type="text" name="phone" />
    </form>
  </div>

export default IndexPage
