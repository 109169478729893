
export const PIN_VERFICATION = "PIN_VERFICATION";
export const PHONE_VERFICATION = "PHONE_VERFICATION";
export const FORM_ENTRY = "FORM_ENTRY";
export const START_VIDEO = "START_VIDEO";
export const TRAINING_VIDEO = "TRAINING_VIDEO";
export const WARNING = "WARNING";
export const SUMMARY_REVIEW = "SUMMARY_REVIEW";
export const QUESTIONAIRE = "QUESTIONAIRE";
export const COMPLETION = "COMPLETION";


