import React, { useState, Fragment } from "react"
import Progress from "react-progressbar"
import Completion from "./Completion"
import PhoneLookup from "./PhoneLookup"
import PinVerfication from "./PinVerfication"
import Enrollment from "./Enrollment"
import StartVideo from "./StartVideo"
import TrainingVideo from "./TrainingVideo"
import Warning from "./Warning"
import Questionaire from "./Questionaire"
import SummaryReview from "./SummaryReview"
import Footer from "./../Navigation/Footer"
import {
  PIN_VERFICATION,
  PHONE_VERFICATION,
  FORM_ENTRY,
  TRAINING_VIDEO,
  QUESTIONAIRE,
  SUMMARY_REVIEW,
  COMPLETION,
  START_VIDEO,
  WARNING,
} from "../../data/types"

const Validation = () => {
  const [nextStep, unLockStep] = useState(PIN_VERFICATION)
  const [progress, setProgress] = useState(0)
  const [locationName, setLocationName] = useState("")
  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const start = 12.5

  const style = {
    inputStyle: {
      outline: "none",
      fontFamily: "monospace",
      borderRadius: "6px",
      border: `1px solid lightgrey`,
      margin: "4px",
      paddingLeft: "8px",
      paddingRight: "8px",
      textAlign: "center",
      verticalAlign: "center",
      alignItems: "center",
      width: "44px",
      height: "54px",
      fontSize: "34px",
      boxSizing: "border-box",
      backgroundColor: "transparent",
    },
  }

  return (
    <Fragment>
      {nextStep === PIN_VERFICATION && (
        <PinVerfication
          style={style}
          nextStep={nextStep}
          unLockStep={unLockStep}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setProgress={setProgress}
        />
      )}
      {nextStep === PHONE_VERFICATION && (
        <PhoneLookup
          progress={start}
          locationName={locationName}
          setLocationName={setLocationName}
          nextStep={nextStep}
          unLockStep={unLockStep}
          inputStyle={style}
          setProgress={setProgress}
        />
      )}
      {nextStep === FORM_ENTRY && (
        <Enrollment
          progress={start * 2}
          nextStep={nextStep}
          unLockStep={unLockStep}
          locationName={locationName}
          setProgress={setProgress}
          setUser={setUser}
        />
      )}
      {nextStep === START_VIDEO && (
        <StartVideo
          progress={start * 3}
          nextStep={nextStep}
          unLockStep={unLockStep}
          setProgress={setProgress}
        />
      )}
      {nextStep === TRAINING_VIDEO && (
        <TrainingVideo
          progress={start * 4}
          nextStep={nextStep}
          unLockStep={unLockStep}
          setProgress={setProgress}
        />
      )}
      {nextStep === WARNING && (
        <Warning
          progress={start * 5}
          nextStep={nextStep}
          unLockStep={unLockStep}
          setProgress={setProgress}
        />
      )}
      {nextStep === SUMMARY_REVIEW && (
        <SummaryReview
          progress={start * 6}
          nextStep={nextStep}
          unLockStep={unLockStep}
          setProgress={setProgress}
        />
      )}
      {nextStep === QUESTIONAIRE && (
        <Questionaire
          progress={start * 7}
          nextStep={nextStep}
          unLockStep={unLockStep}
          setProgress={setProgress}
          user={user}
        />
      )}
      {nextStep === COMPLETION && (
        <Completion
          progress={start * 8}
          nextStep={nextStep}
          unLockStep={unLockStep}
          setProgress={setProgress}
          user={user}
        />
      )}
      {nextStep !== TRAINING_VIDEO && (
        <div className="progress">
          <Progress completed={progress} height={2} color="#4C82FF" />
        </div>
      )}
      {nextStep !== TRAINING_VIDEO && <Footer />}
    </Fragment>
  )
}

export default Validation
