import React from "react"
import { SUMMARY_REVIEW } from "../../data/types"

const Warning = ({ unLockStep, setProgress, progress }) => {
  setProgress(progress)
  return (
    <div className="entry fade">
      <div className="card">
        <h3>MOST IMPORTANTLY</h3>
        <h2 style={{ textAlign: "center", padding: "1em" }}>
          ALWAYS COUNT THE CASH!
        </h2>
        <button
          className="btn"
          type="submit"
          onClick={() => {
            unLockStep(SUMMARY_REVIEW)
            setProgress(75)
          }}
        >
          Continue
        </button>
      </div>
    </div>
  )
}

export default Warning
