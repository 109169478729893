import React from "react";
import Check from "../Icons/Check"

const Completion = ({ setProgress, progress, user }) => {
    setProgress(progress)
    return <div className="entry">
        <div className="card animated fadeInUp">
            <Check height={30} />
            <h3 style={{ padding: 0, margin: 0 }}>Congratulations!</h3>
            <h3>{user.firstName} {user.lastName}</h3>
            <h1>Training Completed.</h1>
            <p style={{ color: "#888" }}>Your login information will be shared with you shortly.<br /></p>
            <p style={{ color: "#888" }}>Make sure to <strong>COLLECT</strong> and <strong>COUNT</strong> cash before completing the transaction.<br /></p>
        </div>
    </div>
}

export default Completion;
