import React, { useState, useEffect } from "react";
import Arrow from "../Icons/Arrow";
import Question from "../Form/Question";
import { tellerQuestions } from "../../data/questions";
import { COMPLETION, TRAINING_VIDEO } from "../../data/types";


const Questionaire = ({ setProgress, unLockStep, progress, user }) => {
    const [question, setQuestion] = useState(0)
    const [notAnswered, setNotAnswered] = useState(false)
    const [inputs, setInputs] = useState({});
    setProgress(progress)

    useEffect(() => { }, [inputs])

    const onChange = (e) => {
        e.persist()
        setInputs(inputs => ({
            ...inputs,
            [e.target.name]: e.target.value
        }));
        setNotAnswered(false)
    }

    const nextQuestion = () => {
        const isAnswered = Object.keys(inputs).length > question
        if (!isAnswered) {
            setNotAnswered(true)
        }

        if (question !== 4 && isAnswered) {
            setQuestion(question + 1)
            setNotAnswered(false)
        } else if (question === 4) {
            validateQuestions(inputs)
        }
    }

    const previousQuestion = () => {
        if (question !== 0) {
            setQuestion(question - 1)
        }
    }

    const onKeyPress = (e) => {
        if (e.keyCode === 39) {
            nextQuestion()
        } else if (e.keyCode === 37) {
            previousQuestion()
        }
    }

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const submit = () => {
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ ...user }),
        }).then(() => unLockStep(COMPLETION)).catch((error) => console.log(error))
    }

    const validateQuestions = (inputs) => {
        const answers = Object.values(inputs)
        if (
            answers[0] === tellerQuestions[0].answer &&
            answers[1] === tellerQuestions[1].answer &&
            answers[2] === tellerQuestions[2].answer &&
            answers[3] === tellerQuestions[3].answer &&
            answers[4] === tellerQuestions[4].answer) {
            submit()
        } else {
            unLockStep(TRAINING_VIDEO)
        }
    }

    return <div className="entry" onKeyDown={e => onKeyPress(e)} tabIndex="0">
        <div >
            <Question
                question={tellerQuestions[question].question}
                choices={tellerQuestions[question].choice}
                length={tellerQuestions.length}
                onChange={onChange}
                notAnswered={notAnswered}
                inputs={inputs}
                index={question + 1}
            />
        </div>
        <Arrow width={45} height={45} className={`arrow right-arrow ${notAnswered && "disabled"}`}
            onClick={nextQuestion} />
        {question !== 0 && <Arrow width={45} height={45}
            className="arrow left-arrow"
            onClick={previousQuestion} />}
    </div>
}

export default Questionaire