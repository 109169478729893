import React, { useEffect, useState, useRef } from "react";
import Progress from 'react-progressbar';
import { WARNING } from "../../data/types"
import ipad from "./../../images/1.png"

const videoURL = "https://storage.googleapis.com/digitalmint-public/bravo-teller-training-video.mp4"

const TrainingVideo = ({ unLockStep }) => {
    const [progress, setProgress] = useState(0)
    const [isCompatible, setCompatible] = useState(false)
    const vidRef = useRef(null);
    const percentage = useRef()

    useEffect(() => {
        setCompatible(/iPhone|iPad|iPod/i.test(window.nagivator && window.navigator.userAgent))
    }, [])

    const animate = () => {
        percentage.current = vidRef.current ? setProgress((vidRef.current.currentTime / vidRef.current.duration) * 100) : null;
        percentage.current = requestAnimationFrame(animate)
    }

    useEffect(() => {
        percentage.current = requestAnimationFrame(animate)
        return () => cancelAnimationFrame(percentage.current)
    })

    useEffect(() => {
        vidRef.current.onended = () => { unLockStep(WARNING) }
    }, [setProgress, unLockStep])


    if (isCompatible && (vidRef.current && vidRef.current.webkitEnterFullScreen)) {
        vidRef.current.webkitEnterFullScreen();
    }

    return <div className="entry cover">
        <div className="video-container animated fadeInLeft">
            <img src={ipad} height="500" alt="" className="video-ipad" />
            <video ref={vidRef} type="video/mp4" src={videoURL} autoPlay playsInline />
        </div>
        <div className="vid-progress">
            <Progress completed={Math.floor(progress)} height={2} color="#50b65a" />
        </div>
        {/* <button style={{ position: "absolute" }} onClick={() => unLockStep(WARNING)}>Next</button> */}
    </div>
}


export default TrainingVideo;
