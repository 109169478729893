

export const tellerQuestions = [
    {
        title: "Question",
        question: "A customer can transact without having an ID?",
        choice: ["true", "false"],
        answer: "false"
    },
    {
        title: "Question",
        question: "When do you collect cash?",
        choice: ["Before signing in", "When typing amount received", "After Bitcoin released"],
        answer: "When typing amount received"
    },
    {
        title: "Question",
        question: "How much is the maximum per transaction?",
        choice: ["$2,500", "$10,000", "$5,000"],
        answer: "$2,500"
    },
    {
        title: "Question",
        question: "How much is the maximum per day with no social security number? ",
        choice: ["$20,000", "$10,000", "$5,000"],
        answer: "$5,000"
    },
    {
        title: "Question",
        question: "How much is the maximum per day with social security number?",
        choice: ["$20,000", "$10,000", "$5,000"],
        answer: "$20,000"
    },
]