import React from "react";



const Question = ({ index, question, notAnswered, choices, length, onChange, inputs }) => {
    return <div className={`card extend  ${notAnswered && "error"}`}>
        <div className={`questions ${notAnswered && "error"}`}>
            <h3 style={{ textAlign: "center" }}>Question:{" "}{index}</h3>
            <div className="question">
                <p>{question}</p>
            </div>
            <div className="choice">
                {choices.map((c, i) =>
                    <span key={i} className="answer">
                        <input name={`question_${index}`}
                            value={c} type="radio"
                            checked={inputs[`question_${index}`] === c}
                            onChange={onChange} />
                        {c}
                    </span>)}
            </div>
        </div>
        <div className="question-index">
            {notAnswered ? <small style={{ color: "red" }}>* required</small> : <small></small>} <small>{index}/{length}</small>
        </div>
    </div >
}

export default Question;