export const Locations = {
  '3026770070': 'DE-Dover-155NDuPont',
  '6066154647': 'OH-Ironton-202Vernon',
  '7634417077': 'MN-ElkRiver-13374Hwy10',
  '7708535807': 'GA-Lakeland-172SValdosta',
  '2036748221': 'CT-Stamford-953Main',
  '4132958464': 'CT-Enfield-2Middle',
  '8436559209': 'LarryScottDemo',
  '3862282000': 'FL-Deltona-2031Saxon',
  '9133411320': 'KS-OverlandPark-8230Metcalf',
  '9736274100': 'NJ-Rockaway-US46',
  '2014629620': 'NJ-HasbrouckHeights-Boulevard',
  '2017039002': 'NJ-FairLawn-Broadway',
  '9734738989': 'NJ-Garfield-RiverDrive',
  '9082453238': 'NJ-Roselle-EastFirstAve',
  '7328888400': 'NJ-Hazlet-StateHighway',
  '6108208117': 'PA-Whitehall-1228MacArthur',
  '3053864660': 'FL-Miami-16794SW88th',
  '2817786127': 'TX-SUGARLAND-LJPKWY',
  '2288180001': 'MS-OCEANSPRINGS-BIENVILLE',
  '4809663330': 'AZ-Tempe-Apache',
  '7176956963': 'PA-Harrisburg-S19th',
  '6107190377': 'PA-WestChester-Gay',
  '7172335000': 'PA-Harrisburg-Agate',
  '7172332444': 'PA-Harrisburg-Market',
  '6185011671': 'IL-GraniteCity-Pontoon',
  '5152625442': 'IA-DesMoines-3947E14th',
  '5152555567': 'IA-WindsorHeights-73rd',
  '5152620762': 'IA-DesMoines-Hubbell',
  '5152789696': 'IA-Urbandale-Douglas',
  '7086302605': 'IL-OakForest-SCentral',
  '7735469005': 'IL-Chicago-5446SKedzie',
  '3142251872': 'MO-ValleyPark-Meramec',
  '8633181511': 'FL-WinterHaven-CypressGardens',
  '6106265000': 'PA-Landsdowne-EBaltimore',
  '6104334960': 'PA-Allentown-820Hamilton',
  '8156248370': 'IL-SouthBeloit-PrairieHill',
  '6093472600':	'NJ-AtlanticCity-3100Atlantic',
  '6099265335':	'NJ-SomersPoint-NewRoad',
  '8567288552':	'NJ-Williamstown-BlackHorse',
  '6093473333':	'NJ-AtlanticCity-1226Atlantic',
  '6096418383':	'NJ-Pleasantville-MartinLutherKing',
  '8563171977':	'NJ-Pennsauken-SCrescent',
  '6098867711':	'NJ-RioGrande-SRt47',
  '6098292572':	'NJ-MaysLanding-BlackHorse',
  '7329360010': 'NJ-RedBank-Bridge',
  '6104345380': 'PA-Allentown-Union',
  '9893540130': 'MI-Alpena-US23',
  '9896230124': 'MI-BadAxe-NVanDyke',
  '2315277000': 'MI-BigRapids-Perry',
  '2318769600': 'MI-Cadillac-Mitchell',
  '2315979700': 'MI-Cheboygan-SMain',
  '9897320605': 'MI-Gaylord-WMain',
  '9892460986': 'MI-Gladwin-ECedar',
  '2318458600': 'MI-Ludington-US10',
  '2313989648': 'MI-Manistee-Parkdale',
  '9897752000': 'MI-MtPleasant-SMission',
  '2313482300': 'MI-Petoskey-WMitchell',
  '9062539000': 'MI-SaultSainteMarie-Ashmun',
  '2319460977': 'MI-TraverseCity-Fourteenth',
  '9893432400': 'MI-WestBranch-Houghton',
  '8155055881': 'IL-Rockford-Huffman',
  '2156085021': 'PA-Philadelphia-Buist',
  '3862521903': 'FL-DaytonaBeach-Nova',
  '6092399101': 'NJ-Burlington-Sunset',
  '3866156446': 'FL-OrmondBeach-Nova',
  '3864265300': 'FL-NewSmyrna-Dixie',
  '3862481119': 'FL-DaytonaBeach-Mason',
  '3867884060': 'FL-DaytonaBeach-Beville',
  '3867893432': 'FL-Deltona-Saxon',
  '4072600661': 'FL-Altamonte-Orienta',
  '3523689969': 'FL-Ocala-14th',
  '3867600505': 'FL-PortOrange-Nova',
  '3867345600': 'FL-Deland-Woodland',
  '3864451154': 'FL-PalmCoast-PalmCoast',
  '2173550010': 'IL-Champaign-EMain',
  '4147689249': 'WI-Cudahy-SPackard',
  '9803520302': 'NC-Monroe-Patton',
  '8564565300': 'NJ-MountEphraim',
  '8563091211': 'NJ-Stratford-WhiteHorsePike',
  '6129916531': 'MN-Minneapolis-Nicollett',
  '2627324169': 'WI-Caledonia-US41',
  '3144291300': 'MO-Overland-Lackland',
  '2679794654': 'NJ-Roebling-Main',
  '7157542445': 'WI-Caroline-Main',
  '2102648212': 'PA-Erie-State',
  "2677215006": "PA-Philadelphia-EButler",
  "8059652262": "CA-SantaBarbara-NMilpas",
  "5595751975": "CA-Fresno-WAshlan",
  "7342878500": "MI-Taylor-Eureka",
  "2108033339": "TX-SanAntonio-NewGuilbeau",
  "6176280860": "MA-Somerville-Broadway",
  "6179455910": "MA-Cambridge-Massachusetts",
  "2014325205": "NJ-JerseyCity-Kennedy",
  "8567844700": "NJ-Clementon-Blackwood",
  "2524529929": "NC-RockyMount-NWesleyan ",
  "4809641656": "University Check Changers",
  "7732684363": "IL-Chicago-E47th",
  "7324770007": "NJ-Brick-Cedarbridge",
  "7329988824": "NJ-TomsRiver-Dover",
  "5308091402": "CA-Chico-East",
  "6192951362": "CA-SanDiego-Park",
  "6362653174": "MO-LakeSTL-Veterans",
  "3144211177": "MO-Stl-N4th",
  "2819833500": "TX-Houston-Beechnut",
  "9098814166": "CA-SanBernardino-NSierra",
  "9093569513": "CA-Fontana-Cherry",
  "7733485474": "Clark Diversey Currency Exchange",
  "6029435529": "AZ-Phoenix-WDunlap",
  "9288552477": "AZ-LakeHavasuCity-McCulloch",
  "7038635757": "VA-Arlington-Columbia",
  "8055254714": "CA-SantaPaula-EMain",
  "5159535100": "IA-DesMoines-ArmyPost",
  "5635823770": "IA-Dubuque-Pennsylvania",
  "5152667980": "IA-DesMoines-EUniversity",
  "5152622552": "IA-DesMoines-E14th",
  "5152518672": "IA-DesMoines-MerleHay",
  "7122588542": "IA-SiouxCity-Hamilton",
  "5159574108": "IA-DesMoines-SW9th",
  "3192266185": "IA-Waterloo-LaPorte",
  "5152260181": "IA-WDesMoines-Grand",
  "5152745200": "IA-DesMoines-Douglas",
  "5159564610": "IA-Ames-Lincoln",
  "5159650071": "IA-Ankey-NAnkey",
  "3193632200": "IA-CedarRapids-BlairsFerry",
  "7122560400": "IA-CouncilBluffs-WBroadway",
  "7122567800": "IA-CouncilBluffs-S24th",
  "3193963520": "IA-CedarRapids-Edgewood",
  "5634451075": "IA-Davenport-NBrady",
  "4176210307": "MO-Joplin-32nd",
  "4178317626": "MO-Springfield-S Glenstone",
  "8166760307": "MO-St. Joseph-S. Belt HWY",
  "7018376333": "ND-Minot-Broadway",
  "7012340085": "ND-Fargo-Main",
  "7017921055": "ND-Grand Forks-Washington",
  "7012937178": "ND-Fargo-University",
  "4024552274": "NE-Omaha-Dodge St",
  "4027381717": "NE-Omaha-L St",
  "4023936683": "NE-Omaha-Maple St",
  "4025939230": "NE-Omaha-96th",
  "4022912620": "NE-Bellevue-Galvin",
  "4029349490": "NE-LaVista-Brentwood",
  "3083844370": "NE-Grand Island-Wilmar",
  "4024660044": "NE-Lincoln-48th",
  "4024943334": "NE-S Sioux City-Dakota Ave",
  "5129539090": "TX-Austin-Montopolis",
  "5126862727": "TX-Austin-AirportBlvd",
  "9734845959": "NJ-Newark-Broad",
  "9082899100": "NJ-Elizabeth-NBroad",
  "9734820022": "NJ-Newark-Broadway",
  "2018672112": "NJ-UnionCity-NewYorkAve",
  "2018639007": "NJ-UnionCity-Bergenline",
  "9087558001": "NJ-Plainfield-Madison, NJ-Plainfield-244EFront",
  "9082223412": "NJ-Plainfield-1472EFront",
  "7328463883": "NJ-NBrunswick-Livingston",
  "9085959200": "NJ-Somerville-SBridge",
  "9085618774": "NJ-NPlainfield-Somerset",
  "9734820001": "NJ-Harrison-FrankERodgers",
  "7322470044": "NJ-NewBrunswick-French",
  "9733610830": "NJ-Dover-Bassett",
  "7329052112": "NJ-Lakewood-River",
  "6092427981": "NJ-ForkedRiver-Main",
  "7327747710": "NJ-Neptune-3rd",
  "9722869801": "TX-BalchSprings-Seagoville",
  "9037229162": "TX-Henderson-Hwy79N",
  "2174315626": "IL-Danville-Hazel",
  "9095907160": "CA-Chino-Central",
  "4808990699": "AZ-Chandler-WGalveston",
  "2109570767": "TX-Schertz-FM78",
  "2107140036": "TX-LiveOak-Toepperwein",
  "6517780640": "MN-StPaul-Arcade",
  "7709926103": "GA-SandySprings-Roswell",
  "4153837411": "CA-Sausalito-Caledonia",
  "4083370332": "CA-Cupertino-StevensCreek",
  "5103370332": "CA-Fremont-PaseoPadre",
  "4135931763": "MA - Chicopee",
  "8169742452": "MO-Raymore-NMadison",
  "6309647625": "IL_Westmont Rockys Vape",
  "7346621790": "MI-AnnArbor-State",
  "7145223670": "CA-BuenaPark-Orangethorpe",
  "7606567225": "CA-PalmSprings-SouthPalmCanyon",
  "7083315354": "Klein - IL - Harvey, 147th Dixie",
  "7083330074": "Klein - IL - Harvey, 159th Halsted",
  "7083398730": "Klein - 167th & Halsted",
  "8159412045": "KLEIN - IL - MORRIS",
  "7734275690": "Klein - Oak Park & Belmont",
  "7087559200": "Klein - IL - South Chicago Heights",
  "7088413130": "Klein - IL - Dolton",
  "7819132528": "MA-Lynn-Essex",
  "2154684200": "PA, Philly-Broad St - United CC",
  "3027922545": "DE- Claymont- Naamans Rd",
  "3023284800": "United CC - DE - New Castle",
  "3023660700": "DE-Newark-Marrows",
  "6105349661": "United CC - PA - Folcroft",
  "6105436470": "United CC - PA - Morton",
  "6108070144": "PA-Bethlehem-Stefko",
  "8568092942": "NJ- WEST BERLIN- Rt 73N",
  "2486230590": "MI-Waterford-Dixie",
  "3138917411": "MI-Hamtramck-Conant",
  "9725560645": "TX-Dallas-NStemmons",
  "7734756634": "IL-Chicago-SWestern",
  "3033719688": "CO-Denver-Peoria",
  "3034770774": "CO-Denver-W38th",
  "3034126274": "CO-Thornton-W88th",
  "9082482759": "NJ-NPlainfield-Rt22W",
  "3126320934": "Demo Branch",
}
