import React from "react";
import { Link } from "gatsby"
import Logo from "./Logo";

const Navbar = () => {
    return (
        <div className="navbar">
            <Link to="/" className="logo-link"><Logo height={27} /></Link>
            <div className="container">
                <Link to="/" className="link">Home</Link>
                <Link to="/FAQ" className="link">FAQ</Link>
                <a class="link" href="https://digitalmint.io">DigitalMint Homepage</a>
            </div>
        </div>
    )
}

export default Navbar;
