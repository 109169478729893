import React, { useState, useEffect } from "react"
import CheckMark from "./../Icons/CheckMark"
import { Locations } from "../../data/locations"
import { FORM_ENTRY } from "../../data/types"

const PhoneLookup = ({
  unLockStep,
  setLocationName,
  setProgress,
  progress,
}) => {
  setProgress(progress)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [validate, setValidation] = useState(false)
  const completed = phoneNumber.length === 13
  const num = phoneNumber.replace(/[^\d\+]/g, "")

  const onChange = e => {
    setPhoneNumber(normalizeInput(e.target.value))
  }

  useEffect(() => {
    if (completed && Locations[num]) {
      setLocationName(Locations[num])
      setTimeout(() => setValidation(true), 600)
      setTimeout(() => unLockStep(FORM_ENTRY), 1600)
    }
  }, [phoneNumber, completed, setLocationName, unLockStep, num])

  const normalizeInput = (value, previousValue) => {
    if (!value) return value
    const currentValue = value.replace(/[^\d]/g, "")
    const cvLength = currentValue.length
    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)})${currentValue.slice(3)}`
      return `(${currentValue.slice(0, 3)})${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`
    }
  }

  return (
    <div className="entry">
      <div
        className={`card extend fade ${completed &&
          !Locations[num] &&
          "error error-text"}`}
      >
        <div className="phone">
          <p>
            <strong>Enter Location's Phone Number</strong>
          </p>
          {!validate && (
            <input
              pattern="[0-9]*"
              name="phone"
              autoFocus
              maxLength={13}
              onChange={onChange}
              value={phoneNumber}
              className={`phone-input ${completed &&
                !Locations[num] &&
                "error phone-error"} ${validate && "phone-success"} `}
              type="tel"
            />
          )}
          {validate && <CheckMark />}
          <br />
        </div>
      </div>
    </div>
  )
}

export default PhoneLookup
